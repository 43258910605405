import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client/core';
import { credentialStorage } from '../CredentialStorage';
import { GetAcceptanceForUserOut, GetDocumentContentOut } from './interfaces';
import * as aws4 from 'aws4';
import { getArgoConfig } from '../../window';
import { get as _get } from 'lodash';

export class ArgoHadesClient {
    fetchWithAWSAuth = (input: RequestInfo,  init?: RequestInit) => {
        const config = getArgoConfig() || {};
        const region = _get(config, 'argo.region');
        const token = credentialStorage.getJwtToken();
        const credentials = credentialStorage.getCustomCredentials();
        let SERVICE_NAME = 'ocean-drift';
        if (!config.argo || !config.argo.isPersonalStack) {
          SERVICE_NAME = 'ocean-wave';
        }
        if (credentials) {
          if (!init) {
            init = {}
          }
          const parsedUrl = new URL(window.location.href);
          let url;
          if(typeof input === 'string') {
            url = input as string;
          } else {
            url = (input as Request).url;
          }
          // The URL constructor accepts either an absolute or relative URL in its first parameter.
          // If the URL is absolute, the second parameter will be ignored. Otherwise, a URL will be
          // constructed using the second parameter as a base URL and using the first parameter as
          // a relative path from the base.
          const fetchUrl = new URL(url, parsedUrl.origin);
          const requestHeaders = {
            'AuthenticationToken': token,
            ...init.headers
          };
          const requestOptions = {
            host: fetchUrl.host,
            path: fetchUrl.pathname,
            uri: fetchUrl.href,
            protocol: fetchUrl.protocol,
            headers: requestHeaders,
            body: init.body,
            method: init.method,
            service: SERVICE_NAME,
            region,
          };
          aws4.sign(requestOptions, {
            secretAccessKey: credentials.secretAccessKey,
            accessKeyId: credentials.accessKeyId,
            sessionToken: credentials.sessionToken
          });
          return fetch(input,requestOptions)
        } else {
          return Promise.reject('AuthManager not enabled or credentials are missing.');
          }
    };

    _getInstance = () => {
        return new ApolloClient({
            cache: new InMemoryCache({}),
            link: new HttpLink({
                uri: '/graphV2',
                fetch: this.fetchWithAWSAuth
            }),
            queryDeduplication: false
        });
    }

    getAcceptanceForUser = async (userName: string): Promise<GetAcceptanceForUserOut> => {
        return await this._getInstance().query({
            query: gql`query GetAcceptanceForUser($userId: String!) {
              getAcceptanceForUser(userId: $userId) {
                  hasAccepted
                  acceptanceDate
                  acceptingUser
                  shouldPromptForAcceptance
              }
          }`,
            variables: {
                userId: userName
            }
        }).then((result) => {
            return result.data.getAcceptanceForUser as GetAcceptanceForUserOut;
        });
    };

    setAcceptanceForUser = async (userName: string) => {
        return await this._getInstance().mutate({
            mutation: gql`mutation SetAcceptanceForUser($userId: String!) {
              setAcceptanceForUser(userId: $userId) {
                 result
              }
          }`,
            variables: {
                userId: userName
            }
        });
    };

    getSiteTerms = async (): Promise<GetDocumentContentOut> => {
        return await this._getInstance().query({
            query: gql`query GetDocumentContent {
              getDocumentContent {
                  content
              }
          }`,
        }).then((result) => {
            return result.data.getDocumentContent as GetDocumentContentOut;
        })
    };
}